import { ref, computed, onBeforeMount } from "vue"

/**
 * This composition function is used to work with scheduling of tests.
 * The representation of scheduling is different on the client and the server (service).
 * This function is used to convert the representation of scheduling from the server to the client and vice versa.
 * Also, it is used to build the object to send to the server with the new scheduling data.
 */

/*
 * @typedef {Object} TimeObjectRepr
 * @property {number} value
 * @property {string} text
 */

/** This type is used to build UI
 * @typedef {Object} ScheduleRepresentation
 * @property {string} every - Time interval. 'week' | 'day' | 'hour' | '* Hrs' | '* Min'
 * @property {number} hour - Hour of the day.
 * @property {number} minute - Minute of the hour.
 * @property {string} on - Day of the week. 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su'
 */

/** Used by the client and the server, just string to represent cron schedule
 * @typedef {string} CronScheduleRepresentation
 */

/** This type is used to build object to send to the server,
 * actually "cron" is a filed with string value, but for better understanding and destructuring
 * this type is returned from function below to 1) get key to delete from object 2) get value to send to the server with filed name
 * @typedef {{cron: string}} ServiceCronScheduleRepresentation
 */


/** The representaion of daily schedule comes/sent from/to the server
 * @typedef {Array<string>} DailySchedule - Array of time strings for daily scheduling.
 */

/** The representaion of weekly schedule comes/sent from/to the server
 * @typedef {Object} WeeklySchedule
 * @property {Array<string>=} mo - Array of time strings for Monday scheduling.
 * @property {Array<string>=} tu - Array of time strings for Tuesday scheduling.
 * @property {Array<string>=} we - Array of time strings for Wednesday scheduling.
 * @property {Array<string>=} th - Array of time strings for Thursday scheduling.
 * @property {Array<string>=} fr - Array of time strings for Friday scheduling.
 * @property {Array<string>=} sa - Array of time strings for Saturday scheduling.
 * @property {Array<string>=} su - Array of time strings for Sunday scheduling.
 */

/**
 * This type is sent to the server
 * @typedef {Object} ServiceScheduleRepresentation
 * @property {DailySchedule} daily - Schedule for daily testing.
 * @property {WeeklySchedule} weekly - Schedule for weekly testing.
 */

/** This type is one of the possible values of scheduledTest.data that comes/sent from/to the server
 * used for tests with default scheduling
 * @typedef {Object} TestDefaultScheduleData
 * @property {DailySchedule} daily
 * @property {WeeklySchedule} weekly
 * @property {Object} startParameters
 * /

/** This type is one of the possible values of scheduledTest.data that comes/sent from/to the server
 * used for tests with cron scheduling
 * @typedef {Object} TestCronScheduleData
 * @property {string} cron
 * @property {Object} startParameters
 * /

/** Type of scheduling, used on the clint
 * @typedef {('cron' | 'default')} SchedulingType
 */



function TimeObjectRepr(i) {
  let text = "";
  let value = null;

  if (Number.isInteger(i)) {
    text = i.toString().padStart(2, "0");
    value = i;
  }

  return { text, value };
}

const PREDEFINED_SCHEDULES = {
  '1 Min': '*:*/1:00',
  '3 Min': '*:*/3:00',
  '5 Min': '*:*/5:00',
  '1 Hrs': '*/1:0:00',
  '2 Hrs': '*/2:0:00',
  '3 Hrs': '*/3:0:00',
}

export const SCHEDULE_COLUMNS = [
  {
    label: 'Every',
    value: 'every',
    type: 'select',
    items: [
      '1 Min',
      '3 Min',
      '5 Min',
      '1 Hrs',
      '2 Hrs',
      '3 Hrs',
      'hour',
      'day',
      'week',
    ],
  },
  {
    label: 'on',
    value: 'on',
    type: 'select',
    items: [
      {
        text: 'Monday',
        value: 'mo',
      },
      {
        text: 'Tuesday',
        value: 'tu',
      },
      {
        text: 'Wednesday',
        value: 'we',
      },
      {
        text: 'Thursday',
        value: 'th',
      },
      {
        text: 'Friday',
        value: 'fr',
      },
      {
        text: 'Saturday',
        value: 'sa',
      },
      {
        text: 'Sunday',
        value: 'su',
      },
    ],
    condition: (row) => row.every === 'week',
  },

  {
    label: 'hour',
    value: 'hour',
    type: 'select',
    items: () => {
      const items = [];
      for (let i = 0; i <= 23; i++) {
        items.push(TimeObjectRepr(i));
      }
      return items;
    },
    condition: (row) => row.every === 'day' || row.every === 'week',
  },

  {
    label: 'minute',
    value: 'minute',
    type: 'select',
    items: () => {
      const items = [];
      for (let i = 0; i <= 59; i++) {
        items.push(TimeObjectRepr(i));
      }
      return items;
    },
    condition: (row) => row.every === 'hour' || row.every === 'day' || row.every === 'week',
  },
]

/**
 * @type {Object} SCHEDULE_TYPE_ENUM
 * @property {SchedulingType} DEFAULT
 * @property {SchedulingType} CRON
 */
export const SCHEDULE_TYPE = {
  DEFAULT: "default",
  CRON: "cron"
}

export const SCHEDULE_TYPE_ITEMS = [
  {
    text: "Default",
    value: SCHEDULE_TYPE.DEFAULT,
  },
  {
    text: "Cron Syntax",
    value: SCHEDULE_TYPE.CRON,
  },
]


export function getSchedulingConfig(scheduledTest) {
  return scheduledTest?.data || {}
}

/**
 * @param {ServiceScheduleRepresentation} scheduleConfigFromService
 */
export function useScheduling(scheduleConfigFromService) {

  /**
   * @type {import('vue').Ref<SchedulingType>}
   */
  const schedulingType = ref(getScheduleType(scheduleConfigFromService))

  /**
   * @type {import('vue').Ref<CronScheduleRepresentation>}
   */
  const _cronScheduleRepr = ref("")

  /**
   * @type {import('vue').Ref<ScheduleRepresentation[]>}
   */
  const _defaultScheduleRepr = ref([])

  const scheduleDataRepr = computed({
    get() {
      if (schedulingType.value === SCHEDULE_TYPE.CRON) {
        return _cronScheduleRepr.value
      } else if (schedulingType.value === SCHEDULE_TYPE.DEFAULT) {
        return _defaultScheduleRepr.value
      }
      return null
    },
    /**
     *
     * @param {ScheduleRepresentation[] | string} value
     */
    set(value) {
      if (schedulingType.value === SCHEDULE_TYPE.CRON) {
        _cronScheduleRepr.value = formatCron(value)
      } else if (schedulingType.value === SCHEDULE_TYPE.DEFAULT && Array.isArray(value)) {
        _defaultScheduleRepr.value = value
      }
    }
  })

  /*
   * Operates with ScheduleRepresentation[]
   */
  const isScheduleEntriesValid = computed(() => {
    // cron empty
    if (schedulingType.value === SCHEDULE_TYPE.CRON) {
      return Boolean(scheduleDataRepr.value.length);
    }

    // default is empty
    if (!scheduleDataRepr.value.length) {
      return false;
    }

    // default has empty values
    for (const entry of scheduleDataRepr.value) {
      if (!entry.every) {
        return false;
      }

      const every = entry.every;

      if (every === "hour") {
        const m = entry.minute;

        if (!Number.isInteger(m)) {
          return false;
        }
      }

      if (every === "day" || every === "week") {
        const h = entry.hour;
        const m = entry.minute;

        if (!Number.isInteger(h) || !Number.isInteger(m)) {
          return false;
        }
      }

      if (every === "week" && !entry.on) {
        return false;
      }
    }

    return true;
  })

  /**
   * @param {ServiceScheduleRepresentation} config
   * @returns SchedulingType
   */
  function getScheduleType(config) {
    if (config[SCHEDULE_TYPE.CRON]?.length) {
      return SCHEDULE_TYPE.CRON
    }
    return SCHEDULE_TYPE.DEFAULT
  }

  function formatCron(str) {
    return str.replace('\\n', '\n')
  }

  function convertToCronRepresentation(serviceScheduleRepr) {
    return formatCron(serviceScheduleRepr?.cron) || '';
  }

  /**
   * @param {ServiceScheduleRepresentation} serviceScheduleRepr
   * @returns {ScheduleRepresentation[]} - Array of schedule representations.
   */
  function convertToDefaultScheduleRepr(serviceScheduleRepr) {
    const schedule = [];
    const listOfDaily = serviceScheduleRepr?.daily || [];
    const mapOfWeekly = serviceScheduleRepr?.weekly || {};
    for (const daily of listOfDaily) {
      const predefinedSchedule = Object.entries(PREDEFINED_SCHEDULES).find(([, v]) => v === daily);
      if (predefinedSchedule) {
        schedule.push({
          every: predefinedSchedule[0],
        });
        continue;
      }

      const dailySplit = daily.split(':');
      if (dailySplit[0] === '*') {
        schedule.push({
          every: 'hour',
          hour: 0,
          minute: parseInt(dailySplit[1], 10)
        });
      } else {
        schedule.push({
          every: 'day',
          hour: parseInt(dailySplit[0], 10),
          minute: parseInt(dailySplit[1], 10),
        });
      }
    }

    for (const [weekDay, listOfTimes] of Object.entries(mapOfWeekly)) {
      if (!listOfTimes || !Array.isArray(listOfTimes) || !listOfTimes.length) {
        continue;
      }
      for (const time of listOfTimes) {
        const [hour, minute] = time.split(':');
        schedule.push({
          every: 'week',
          on: weekDay,
          hour: parseInt(hour, 10),
          minute: parseInt(minute, 10),
        });
      }
    }

    return schedule;
  }

  /**
   * @param {string=} cronString - actual cron string
   * @return {ServiceCronScheduleRepresentation} - object that contains "cron" field with cron string
   */
  function getServiceCronRepr(cronString = null) {
    if (typeof cronString === 'string') {
      return { cron: formatCron(cronString) }
    }
    return { cron: "" }
  }

  /** Gets service's repersentation of scheduling from the UI representation
   *  to send to the backend.
   * @param {ScheduleRepresentation[]=} scheduleData - Array of schedule representations.
   * @returns {ServiceScheduleRepresentation} - service schedule representation to send to the backend
   */
  function getServiceDefaultScheduleRepr(scheduleData = null) {
    const defaultRepr = { daily: [], weekly: {} }
    if (!scheduleData) {
      return defaultRepr
    }

    return scheduleData.reduce((acc, valueItem) => {
      if (!valueItem.every) {
        return acc;
      }
      if (valueItem.every === 'week') {
        if (!acc.weekly[valueItem.on]) {
          acc.weekly[valueItem.on] = [];
        }
        acc.weekly[valueItem.on].push(`${valueItem.hour}:${valueItem.minute}:00`);
      } else if (valueItem.every === 'day' || valueItem.every === 'hour') {
        let dailyTimeString = '';
        if (valueItem.every === 'day') {
          dailyTimeString = `${valueItem.hour}`;
        } else {
          dailyTimeString += '*';
        }
        dailyTimeString += `:${valueItem.minute}:00`;
        acc.daily.push(dailyTimeString);
      } else {
        acc.daily.push(PREDEFINED_SCHEDULES[valueItem.every]);
      }

      return acc
    }, defaultRepr)
  }


  /** Receive scheduled test object, adds new scheduling data to it and returns it.
    * @param {{data: (TestDefaultScheduleData | TestCronScheduleData)}} testObj - Scheduled test object.
    * @return {{data: (TestDefaultScheduleData | TestCronScheduleData)}} - Scheduled test object with new scheduling data.
    */
  function updateWithNewSchedulingData(testObj) {
    // eslint-disable-next-line no-undef
    const copy = structuredClone(testObj)
    const defaultKeys = Object.keys(getServiceDefaultScheduleRepr())
    const cronKeys = Object.keys(getServiceCronRepr())

    if (schedulingType.value === SCHEDULE_TYPE.CRON && typeof scheduleDataRepr.value === "string") {
      for (const key of defaultKeys) {
        delete copy.data[key]
      }

      copy.data = {
        ...copy.data,
        ...getServiceCronRepr(scheduleDataRepr.value),
      }
    } else if (schedulingType.value === SCHEDULE_TYPE.DEFAULT && Array.isArray(scheduleDataRepr.value)) {
      for (const key of cronKeys) {
        delete copy.data[key]
      }

      copy.data = {
        ...copy.data,
        ...getServiceDefaultScheduleRepr(scheduleDataRepr.value),
      }
    }

    return copy
  }

  function init() {
    if (schedulingType.value === SCHEDULE_TYPE.CRON) {
      scheduleDataRepr.value = convertToCronRepresentation(scheduleConfigFromService)
    } else if (schedulingType.value === SCHEDULE_TYPE.DEFAULT) {
      scheduleDataRepr.value = convertToDefaultScheduleRepr(scheduleConfigFromService)
    }
  }

  onBeforeMount(() => {
    init()
  })

  return {
    scheduleDataRepr,
    schedulingType,
    updateWithNewSchedulingData,
    isScheduleEntriesValid,
  }
}
